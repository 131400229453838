@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500&display=swap');

:root {
  --bg: #ffffff;
  --text: #000000;
  
  --col: #ff30a0;
  --Lcol: #ffcce8;
  --Dcol: #bf247a;
  --grey: #aca3bd;
  
  --cardborder: #8f8f8f;
  --cardbg:#ffffff;
  --descbg:#e8e8e8;
  
  --labelbg: #000000;
  --labeltext: #fff;
  
  --bright0: 0%;
  --bright100: 1000%;
  
  --faintlvl: 50%;
  
}



body {
  font-family: 'Fredoka';
  font-size: 22px;
  font-weight: 400;
  
  font-variant-emoji: emoji;
  
  background-color: var(--bg);
  color: var(--text);
}

a {
  color: var(--col);
  font-weight: 500;
}

a:visited {
  color: var(--Dcol);
}

a:hover {
  color: #ffffff;
  background-color: var(--col);
}

a:active {
  color: #ffffff;
  font-weight: 500;
  background-color: var(--Dcol);
  text-shadow: 0px -1px var(--Lcol);
}

h1 {
  text-shadow: -1px -3px var(--col);
  font-weight: 500;
}

/*
hr {
  background-color: unset;
  border-top: 2px dashed var(--col);
  filter: unset;
  opacity: 100%;
}
*/