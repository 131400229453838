

.butt {
  position: relative;
  top: -5px;
  box-shadow: 0px 5px var(--Dcol);
  
  color: #fff;
  background-color: var(--col);
  border: solid 2px var(--col);
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  
  transition: top 0.1s, box-shadow 0.1s, background-color 0.1s;
}

.butt:hover {
  top: -4px;
  box-shadow: 0px 4px var(--Dcol);
  border-color: var(--Lcol);
}

.butt:active {
  top: 0px;
  box-shadow: 0px 0px var(--Dcol);
  
  border-color: var(--col);
  background-color: var(--Dcol);
  
  transition: top 0s, box-shadow 0s;
}


.butt .label {
  visibility: hidden;
  background-color: var(--labelbg);
  color: var(--labeltext);
  font-size: 14px;
  border-radius: 4px;
  
  position: absolute;
  top: 102%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 1;
  
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  
  width: max-content;
  overflow: visible;
}

.butt:hover .label {
  visibility: visible;
}

.butt .label::after {
  content: " ";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--labelbg) transparent;
}