.title {
  margin-top: 20px;
  text-align: center;
  font-family: 'Fredoka One', cursive;
  font-size: 60px;
}

.bgdiv {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -2;
}

.bggif {
/*  max-width: 150%; */
  position: absolute;
  top: 50%;
  left: 50%;
  image-rendering: pixelated;
  transform: translate(-50%, -50%) scale(3);
/*  filter: blur(3px); */
  overflow: hidden;

  z-index: -1;
}

.bggradient {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 0;
  
  background: radial-gradient(circle, #ffffff30 0%, #ffffff30 20%, #ff30a020 44%, #00000000 100%);
}




.patreonLogo {
  max-width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.patreonLogo:hover {
  cursor: pointer;
}

.gamecard:hover {
  position:relative; top:-4px;
  box-shadow: 0px 8px #ff30a0;
}

.year {
  background: #eeeeee;
  border-color: #888888;
  border-width: 1px;
  border-radius: 20px;
  border-style: dotted;
  padding: 5px;
  
  align-items: center;
  justify-items: center;
  
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.year:after {
  clear: both;
  content: '';
  display: table;
}

.month {
  
  border-radius: 10px;
}




