.slot {
  display: inline-block;
  
  position: relative;
  height: 40px;
  width: 100%;
  padding: 0px;
}

.icon {
  width: 68;
  height: 68;
  
  border-color: transparent;
  border-radius: 4px;
  
  position: absolute;
  top: -2px;
  right: 1%;
  
  background-color: unset;
  
  padding: 2px;
}

.icon:hover {
  background-color: var(--col);
}

.icon:active {
  background-color: var(--Dcol);
}


.icon .pic {
  background-color: transparent;
  position: relative;
  top: 1px;
  left: -1px;
  image-rendering: pixelated;
  filter: brightness(var(--bright0)) drop-shadow(1px -1px 0px var(--col));
}

.icon:hover .pic {
  top: 0px;
  left: 0px;
  filter: brightness(var(--bright100));
}

.icon:active .pic {
  top: 0px;
  left: 0px;
  filter: brightness(var(--bright100)) drop-shadow(0px -1px 0px var(--col));
}


.icon .label {
  visibility: hidden;
  background-color: var(--labelbg);
  color: var(--labeltext);
  font-size: 14px;
  border-radius: 4px;
  
  position: absolute;
  top: 68px;
  right: -8px;
  z-index: 1;
  
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  
  width: max-content;
  overflow: visible;
}

.icon:hover .label {
  visibility: visible;
}

.icon .label::after {
  content: " ";
  position: absolute;
  top: -10px;
  right: 36px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--labelbg) transparent;
}
