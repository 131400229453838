.card {
  width: 400px;
  max-width: 96%;
  
  margin-top: 5px;
  margin: 8px;
  
  border-style: solid;
  border_width: 1px 1px 0px 1px;
  border-color: #888;
  border-radius: 10px;
  box-shadow: 0px 4px #ff30a0;
}

.card:hover {
  position:relative; top:-4px;
  box-shadow: 0px 8px #ff30a0;
}

.gameTitle {
  width: 320px;
  text-shadow: 0px 1px #ff30a0;
  font-size: 28px;
  font-weight: bold;
}

.gameEmoji {
  position: absolute;
  right: 3px;
  top: -16px;
  font-size: 42px;
  text-shadow: 0px 3px #ff30a0;
}


.butt {
  background-color: #ff30a0;
  border-color: #ff30a0;
  margin: 5px;
}

.butt:hover {
  border-width: 4px;
  background-color: #bf247a;
}

.butt:active {
  border-width: 4px;
  background-color: #bf247a;
}