@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500&display=swap);
.DayNight_slot__vqfoh {
  display: inline-block;
  
  position: relative;
  height: 40px;
  width: 100%;
  padding: 0px;
}

.DayNight_icon__3XnSu {
  width: 68;
  height: 68;
  
  border-color: transparent;
  border-radius: 4px;
  
  position: absolute;
  top: -2px;
  right: 1%;
  
  background-color: unset;
  
  padding: 2px;
}

.DayNight_icon__3XnSu:hover {
  background-color: var(--col);
}

.DayNight_icon__3XnSu:active {
  background-color: var(--Dcol);
}


.DayNight_icon__3XnSu .DayNight_pic__APStm {
  background-color: transparent;
  position: relative;
  top: 1px;
  left: -1px;
  image-rendering: pixelated;
  -webkit-filter: brightness(var(--bright0)) drop-shadow(1px -1px 0px var(--col));
          filter: brightness(var(--bright0)) drop-shadow(1px -1px 0px var(--col));
}

.DayNight_icon__3XnSu:hover .DayNight_pic__APStm {
  top: 0px;
  left: 0px;
  -webkit-filter: brightness(var(--bright100));
          filter: brightness(var(--bright100));
}

.DayNight_icon__3XnSu:active .DayNight_pic__APStm {
  top: 0px;
  left: 0px;
  -webkit-filter: brightness(var(--bright100)) drop-shadow(0px -1px 0px var(--col));
          filter: brightness(var(--bright100)) drop-shadow(0px -1px 0px var(--col));
}


.DayNight_icon__3XnSu .DayNight_label__2i-ae {
  visibility: hidden;
  background-color: var(--labelbg);
  color: var(--labeltext);
  font-size: 14px;
  border-radius: 4px;
  
  position: absolute;
  top: 68px;
  right: -8px;
  z-index: 1;
  
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  
  width: -webkit-max-content;
  
  width: max-content;
  overflow: visible;
}

.DayNight_icon__3XnSu:hover .DayNight_label__2i-ae {
  visibility: visible;
}

.DayNight_icon__3XnSu .DayNight_label__2i-ae::after {
  content: " ";
  position: absolute;
  top: -10px;
  right: 36px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--labelbg) transparent;
}



.TopBar_bar__bQal1 {
  background-color: var(--bg);
  color: var(--text);
  
  padding-top: 0px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  
  border-radius: 16px;
  border-bottom: 2px dashed var(--text);
}

.TopBar_link__3q7TK {
  margin-left: 4px;
  padding-left: 4px;
  padding-right: 4px;
  transition: color 0s;
}

.TopBar_link__3q7TK .TopBar_text__1AWb0 {
  color: var(--text);
  margin: 0px;
  
  pointer-events: none;
  
  line-height: 45px;
  font-size: 26px;
}

.TopBar_link__3q7TK:hover .TopBar_text__1AWb0 {
  color: #ffffff;
}

.TopBar_link__3q7TK .TopBar_punkcake__3P4gw {
  height: 100%;
  pointer-events: none;
}

.TopBar_link__3q7TK:hover .TopBar_punkcake__3P4gw {
  -webkit-filter: invert(100%) hue-rotate(180deg);
          filter: invert(100%) hue-rotate(180deg);
}



.Button_butt__1_UON {
  position: relative;
  top: -5px;
  box-shadow: 0px 5px var(--Dcol);
  
  color: #fff;
  background-color: var(--col);
  border: solid 2px var(--col);
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  
  transition: top 0.1s, box-shadow 0.1s, background-color 0.1s;
}

.Button_butt__1_UON:hover {
  top: -4px;
  box-shadow: 0px 4px var(--Dcol);
  border-color: var(--Lcol);
}

.Button_butt__1_UON:active {
  top: 0px;
  box-shadow: 0px 0px var(--Dcol);
  
  border-color: var(--col);
  background-color: var(--Dcol);
  
  transition: top 0s, box-shadow 0s;
}


.Button_butt__1_UON .Button_label__3eOF0 {
  visibility: hidden;
  background-color: var(--labelbg);
  color: var(--labeltext);
  font-size: 14px;
  border-radius: 4px;
  
  position: absolute;
  top: 102%;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  z-index: 1;
  
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  
  width: -webkit-max-content;
  
  width: max-content;
  overflow: visible;
}

.Button_butt__1_UON:hover .Button_label__3eOF0 {
  visibility: visible;
}

.Button_butt__1_UON .Button_label__3eOF0::after {
  content: " ";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--labelbg) transparent;
}
.Footer_footer__3Ztza {
  text-align: center;
  
  border-radius: 32px 32px 0px 0px;
  background-color: var(--descbg);
  margin-top: 64px;
  margin-bottom: 0px;
  height: 32px;
}

.Index_title__2BBZg {
  margin-top: 20px;
  text-align: center;
  font-family: 'Fredoka One', cursive;
  font-size: 60px;
}

.Index_bgdiv__2YKZA {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -2;
}

.Index_bggif__3rvdo {
/*  max-width: 150%; */
  position: absolute;
  top: 50%;
  left: 50%;
  image-rendering: pixelated;
  -webkit-transform: translate(-50%, -50%) scale(3);
          transform: translate(-50%, -50%) scale(3);
/*  filter: blur(3px); */
  overflow: hidden;

  z-index: -1;
}

.Index_bggradient__1R9rt {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 0;
  
  background: radial-gradient(circle, #ffffff30 0%, #ffffff30 20%, #ff30a020 44%, #00000000 100%);
}




.Index_patreonLogo__38Uqu {
  max-width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Index_patreonLogo__38Uqu:hover {
  cursor: pointer;
}

.Index_gamecard__1QlU1:hover {
  position:relative; top:-4px;
  box-shadow: 0px 8px #ff30a0;
}

.Index_year__1qE4U {
  background: #eeeeee;
  border-color: #888888;
  border-width: 1px;
  border-radius: 20px;
  border-style: dotted;
  padding: 5px;
  
  align-items: center;
  justify-items: center;
  
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Index_year__1qE4U:after {
  clear: both;
  content: '';
  display: table;
}

.Index_month__1O9oj {
  
  border-radius: 10px;
}







.GameCard_gameYear__1COWi {
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  text-shadow: 0px 3px #ff30a0;
  margin-top: 32px;
}


.GameCard_gameGrid__1EzQr {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}


.GameCard_gameCard__i8H4Z {
  --cardcol: var(--col);
  
  position: relative;
  top: 0px;
  width: 350px;
  max-width: 96%;
/*  height: 550px; */
  
  display: flex;
  flex-flow: column;
  
  margin: 16px;
  margin-bottom: 32px;
  
  padding: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  
  background-color: var(--cardbg);
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  border-color: var(--cardborder);
  border-radius: 10px;
  box-shadow: 0px 4px var(--cardcol);
  
  transition: top 0.3s, box-shadow 0.3s;
}

.GameCard_gameCard__i8H4Z:hover {
  position: relative; top:-4px;
  box-shadow: 0px 8px var(--cardcol);
  
  transition: top 0.1s, box-shadow 0.1s;
}

.GameCard_header__3mOql {
  display: flex;
  position: relative;
}

.GameCard_gameTitle__3miUS {
  flex: 1 1 auto;
  overflow-wrap: anywhere;
  
  min-height: 4rem;
  text-shadow: 0px 1px var(--cardcol);
  font-size: 28px;
  font-weight: bold;
}

.GameCard_gameEmoji__3Z5LN {
  flex: 0 0 auto;
  margin-left: -16px;
  
  position: relative;
  right: -12px;
  top: -24px;
  font-size: 42px;
  text-outline: 1px var(--text);
  text-shadow: 0px 3px var(--cardcol);
}

.GameCard_gamePeriod__E2bFE {
  position: absolute;
  bottom: -12px;
  right: 0px;
  color: var(--grey);
  font-size: 20px;
}


.GameCard_gameDesc__2OgG1 {
  border-radius: 10px;
  background: var(--descbg);
  
  margin-left: -7px;
  margin-right: -7px;
  margin-bottom: 8px;
  
  padding: 8px;
  font-size: 18px;
}


.GameCard_gameThumb__1DopX {
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  
  width: 100%;
  aspect-ratio: 315/250;
  
  position: relative;
  margin-bottom: 10px;
  
  border-radius: 10px;
  background: var(--descbg);
  border: 1px solid var(--text);
  
  overflow: hidden;
}

.GameCard_gameThumb__1DopX .GameCard_anim__276Lp {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  
  image-rendering: crisp-edges;
  
  flex: 0 0 auto;
}

.GameCard_gameThumb__1DopX .GameCard_still__uXRGz {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  
  image-rendering: crisp-edges;
  
  flex: 0 0 auto;
  
  z-index: 10;
  opacity: 1;
  transition: opacity 0.4s;
}

.GameCard_gameThumb__1DopX:hover .GameCard_still__uXRGz {
  opacity: 0;
  transition: opacity 0.2s;
}

.GameCard_gameThumb__1DopX .GameCard_play__37e_s {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  
  z-index: 20;
  opacity: 1;
  transition: opacity 0.4s;
}
.GameCard_gameThumb__1DopX:hover .GameCard_play__37e_s {
  opacity: 0;
  transition: opacity 0.2s;
}

.GameCard_separator__2IVZD {
  background-color: unset;
  -webkit-filter: unset;
          filter: unset;
  
  border-top: 2px dashed var(--cardcol);
  margin: 0px;
  margin-top: 4px;
  margin-bottom: 7px;
  opacity: 1;
  
  height: 1px;
  clear: both;
  display: block;
  width: 100%;
  flex: 0 0 auto;
}


.GameCard_iconRow__2VrE2 {
  position: static;
  height: 40px;
}

.GameCard_gameIconSlot__2kKke {
  display: inline-block;
  margin-right: 4px;
}

.GameCard_gameIcon__3lRdF {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  
  position: relative;
  background-color: unset;
  
  padding: 4px;
}

.GameCard_gameIcon__3lRdF:hover {
  background-color: var(--col);
}

.GameCard_gameIcon__3lRdF:active {
  background-color: var(--Dcol);
}


.GameCard_gameIcon__3lRdF .GameCard_pic__3Tky- {
  position: relative;
  top: -3px;
  image-rendering: pixelated;
  -webkit-filter: brightness(var(--bright0)) drop-shadow(0px 1px 0px var(--col));
          filter: brightness(var(--bright0)) drop-shadow(0px 1px 0px var(--col));
}

.GameCard_gameIcon__3lRdF:hover .GameCard_pic__3Tky- {
  top: -2px;
  -webkit-filter: brightness(var(--bright100));
          filter: brightness(var(--bright100));
}

.GameCard_gameIcon__3lRdF:active .GameCard_pic__3Tky- {
  top: -1px;
  -webkit-filter: brightness(var(--bright100)) drop-shadow(0px -1px 0px var(--col));
          filter: brightness(var(--bright100)) drop-shadow(0px -1px 0px var(--col));
}


.GameCard_gameIcon__3lRdF .GameCard_label__1o0W4 {
  visibility: hidden;
  background-color: var(--labelbg);
  color: var(--labeltext);
  font-size: 14px;
  border-radius: 4px;
  
  position: absolute;
  top: 44px;
  left: -8px;
  z-index: 3;
  
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  
  width: -webkit-max-content;
  
  width: max-content;
  overflow: visible;
}

.GameCard_gameIcon__3lRdF:hover .GameCard_label__1o0W4 {
  visibility: visible;
}

.GameCard_gameIcon__3lRdF .GameCard_label__1o0W4::after {
  content: " ";
  position: absolute;
  top: -10px;
  left: 28px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--labelbg) transparent;
}

.GameCard_gameNum__2YuEU {
  position: absolute;
  right: 8px;
  bottom: -14px;
  color: var(--bg);
  font-weight: 500;
  font-size: 26px;
  text-shadow: -1px -1px var(--cardcol), 1px -1px var(--cardcol), -1px 1px var(--cardcol), 0px 2px var(--cardcol), 2px 0px var(--cardcol), 2px 2px var(--cardcol);
}


.GameCard_ownerLine__3uNZ8 {
  font-size: 14px;
  margin-bottom: 12px;
  text-align: center;
}

.GameCard_keyLine__1LTGq {
  display: flex;
  flex: 0 1 auto;
  font-size: 18px;
  width: 100%;
  
  height: 25px;
  margin-bottom: 8px;
}

.GameCard_keyRevealed__G5We5 {
  border: 2px solid var(--col);
  border-radius: 4px;
  
  background-color: unset;
  color: hsl(0deg 0% var(--faintlvl));
  
  position: relative;
  top: -1px;
  box-shadow: 0px 1px var(--Dcol);
  
  line-height: 18px;
  
  display: flex;
  flex: 1 1 auto;
  
  transition: border-color 0.2s;
}

.GameCard_keyRevealed__G5We5:hover {
  top: 0px;
  box-shadow: 0px 0px var(--Dcol);
}

.GameCard_keyRevealed__G5We5:active {
  border-color: var(--Lcol);
  transition: border-color 0s;
}

.GameCard_keyRevealed__G5We5 .GameCard_key__2fQHU {
  background-color: transparent;
  color: hsv(0,0,var(--faint));
  padding: 0px;
  margin: 0px;
  
  position: relative;
  left: 0px;
  top: -3px;
  width: 100%;
  
  border: unset;
  outline: unset;
}

.GameCard_keyRevealed__G5We5 .GameCard_label__1o0W4 {
  visibility: hidden;
  background-color: var(--labelbg);
  color: var(--labeltext);
  font-size: 14px;
  border-radius: 4px;
  
  position: absolute;
  top: 23px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  z-index: 3;
  
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  
  width: -webkit-max-content;
  
  width: max-content;
  overflow: visible;
}

.GameCard_keyRevealed__G5We5:hover .GameCard_label__1o0W4 {
  visibility: visible;
}

.GameCard_keyRevealed__G5We5 .GameCard_label__1o0W4::after {
  content: " ";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--labelbg) transparent;
}

.GameCard_keyRevealed__G5We5 .GameCard_icon__1oszt {
  position: relative;
  top: 1px;
  height: 16px;
  image-rendering: pixelated;
  -webkit-filter: brightness(var(--faintlvl));
          filter: brightness(var(--faintlvl));
}



.Form_label__N-rCg {
	font-weight: bold;
	
}

.Form_tip__1SrBx {
	font-style: italic;
	font-weight: 400;
	font-size: 18px;
	opacity: 90%;
	
	margin-left: 8px;
	
  display: inline;
}


.Index_card__3PrNL {
  width: 400px;
  max-width: 96%;
  
  margin-top: 5px;
  margin: 8px;
  
  border-style: solid;
  border_width: 1px 1px 0px 1px;
  border-color: #888;
  border-radius: 10px;
  box-shadow: 0px 4px #ff30a0;
}

.Index_card__3PrNL:hover {
  position:relative; top:-4px;
  box-shadow: 0px 8px #ff30a0;
}

.Index_gameTitle__24iFo {
  width: 320px;
  text-shadow: 0px 1px #ff30a0;
  font-size: 28px;
  font-weight: bold;
}

.Index_gameEmoji__wpzus {
  position: absolute;
  right: 3px;
  top: -16px;
  font-size: 42px;
  text-shadow: 0px 3px #ff30a0;
}


.Index_butt__1-Dzf {
  background-color: #ff30a0;
  border-color: #ff30a0;
  margin: 5px;
}

.Index_butt__1-Dzf:hover {
  border-width: 4px;
  background-color: #bf247a;
}

.Index_butt__1-Dzf:active {
  border-width: 4px;
  background-color: #bf247a;
}
:root {
  --bg: #ffffff;
  --text: #000000;
  
  --col: #ff30a0;
  --Lcol: #ffcce8;
  --Dcol: #bf247a;
  --grey: #aca3bd;
  
  --cardborder: #8f8f8f;
  --cardbg:#ffffff;
  --descbg:#e8e8e8;
  
  --labelbg: #000000;
  --labeltext: #fff;
  
  --bright0: 0%;
  --bright100: 1000%;
  
  --faintlvl: 50%;
  
}



body {
  font-family: 'Fredoka';
  font-size: 22px;
  font-weight: 400;
  
  font-variant-emoji: emoji;
  
  background-color: #ffffff;
  
  background-color: var(--bg);
  color: #000000;
  color: var(--text);
}

a {
  color: #ff30a0;
  color: var(--col);
  font-weight: 500;
}

a:visited {
  color: #bf247a;
  color: var(--Dcol);
}

a:hover {
  color: #ffffff;
  background-color: #ff30a0;
  background-color: var(--col);
}

a:active {
  color: #ffffff;
  font-weight: 500;
  background-color: #bf247a;
  background-color: var(--Dcol);
  text-shadow: 0px -1px #ffcce8;
  text-shadow: 0px -1px var(--Lcol);
}

h1 {
  text-shadow: -1px -3px #ff30a0;
  text-shadow: -1px -3px var(--col);
  font-weight: 500;
}

/*
hr {
  background-color: unset;
  border-top: 2px dashed var(--col);
  filter: unset;
  opacity: 100%;
}
*/
