

.bar {
  background-color: var(--bg);
  color: var(--text);
  
  padding-top: 0px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  
  border-radius: 16px;
  border-bottom: 2px dashed var(--text);
}

.link {
  margin-left: 4px;
  padding-left: 4px;
  padding-right: 4px;
  transition: color 0s;
}

.link .text {
  color: var(--text);
  margin: 0px;
  
  pointer-events: none;
  
  line-height: 45px;
  font-size: 26px;
}

.link:hover .text {
  color: #ffffff;
}

.link .punkcake {
  height: 100%;
  pointer-events: none;
}

.link:hover .punkcake {
  filter: invert(100%) hue-rotate(180deg);
}
