

.label {
	font-weight: bold;
	
}

.tip {
	font-style: italic;
	font-weight: 400;
	font-size: 18px;
	opacity: 90%;
	
	margin-left: 8px;
	
  display: inline;
}

