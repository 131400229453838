

.gameYear {
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  text-shadow: 0px 3px #ff30a0;
  margin-top: 32px;
}


.gameGrid {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}


.gameCard {
  --cardcol: var(--col);
  
  position: relative;
  top: 0px;
  width: 350px;
  max-width: 96%;
/*  height: 550px; */
  
  display: flex;
  flex-flow: column;
  
  margin: 16px;
  margin-bottom: 32px;
  
  padding: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  
  background-color: var(--cardbg);
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  border-color: var(--cardborder);
  border-radius: 10px;
  box-shadow: 0px 4px var(--cardcol);
  
  transition: top 0.3s, box-shadow 0.3s;
}

.gameCard:hover {
  position: relative; top:-4px;
  box-shadow: 0px 8px var(--cardcol);
  
  transition: top 0.1s, box-shadow 0.1s;
}

.header {
  display: flex;
  position: relative;
}

.gameTitle {
  flex: 1 1 auto;
  overflow-wrap: anywhere;
  
  min-height: 4rem;
  text-shadow: 0px 1px var(--cardcol);
  font-size: 28px;
  font-weight: bold;
}

.gameEmoji {
  flex: 0 0 auto;
  margin-left: -16px;
  
  position: relative;
  right: -12px;
  top: -24px;
  font-size: 42px;
  text-outline: 1px var(--text);
  text-shadow: 0px 3px var(--cardcol);
}

.gamePeriod {
  position: absolute;
  bottom: -12px;
  right: 0px;
  color: var(--grey);
  font-size: 20px;
}


.gameDesc {
  border-radius: 10px;
  background: var(--descbg);
  
  margin-left: -7px;
  margin-right: -7px;
  margin-bottom: 8px;
  
  padding: 8px;
  font-size: 18px;
}


.gameThumb {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  
  width: 100%;
  aspect-ratio: 315/250;
  
  position: relative;
  margin-bottom: 10px;
  
  border-radius: 10px;
  background: var(--descbg);
  border: 1px solid var(--text);
  
  overflow: hidden;
}

.gameThumb .anim {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  
  image-rendering: crisp-edges;
  
  flex: 0 0 auto;
}

.gameThumb .still {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  
  image-rendering: crisp-edges;
  
  flex: 0 0 auto;
  
  z-index: 10;
  opacity: 1;
  transition: opacity 0.4s;
}

.gameThumb:hover .still {
  opacity: 0;
  transition: opacity 0.2s;
}

.gameThumb .play {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  
  z-index: 20;
  opacity: 1;
  transition: opacity 0.4s;
}
.gameThumb:hover .play {
  opacity: 0;
  transition: opacity 0.2s;
}

.separator {
  background-color: unset;
  filter: unset;
  
  border-top: 2px dashed var(--cardcol);
  margin: 0px;
  margin-top: 4px;
  margin-bottom: 7px;
  opacity: 1;
  
  height: 1px;
  clear: both;
  display: block;
  width: 100%;
  flex: 0 0 auto;
}


.iconRow {
  position: static;
  height: 40px;
}

.gameIconSlot {
  display: inline-block;
  margin-right: 4px;
}

.gameIcon {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  
  position: relative;
  background-color: unset;
  
  padding: 4px;
}

.gameIcon:hover {
  background-color: var(--col);
}

.gameIcon:active {
  background-color: var(--Dcol);
}


.gameIcon .pic {
  position: relative;
  top: -3px;
  image-rendering: pixelated;
  filter: brightness(var(--bright0)) drop-shadow(0px 1px 0px var(--col));
}

.gameIcon:hover .pic {
  top: -2px;
  filter: brightness(var(--bright100));
}

.gameIcon:active .pic {
  top: -1px;
  filter: brightness(var(--bright100)) drop-shadow(0px -1px 0px var(--col));
}


.gameIcon .label {
  visibility: hidden;
  background-color: var(--labelbg);
  color: var(--labeltext);
  font-size: 14px;
  border-radius: 4px;
  
  position: absolute;
  top: 44px;
  left: -8px;
  z-index: 3;
  
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  
  width: max-content;
  overflow: visible;
}

.gameIcon:hover .label {
  visibility: visible;
}

.gameIcon .label::after {
  content: " ";
  position: absolute;
  top: -10px;
  left: 28px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--labelbg) transparent;
}

.gameNum {
  position: absolute;
  right: 8px;
  bottom: -14px;
  color: var(--bg);
  font-weight: 500;
  font-size: 26px;
  text-shadow: -1px -1px var(--cardcol), 1px -1px var(--cardcol), -1px 1px var(--cardcol), 0px 2px var(--cardcol), 2px 0px var(--cardcol), 2px 2px var(--cardcol);
}


.ownerLine {
  font-size: 14px;
  margin-bottom: 12px;
  text-align: center;
}

.keyLine {
  display: flex;
  flex: 0 1 auto;
  font-size: 18px;
  width: 100%;
  
  height: 25px;
  margin-bottom: 8px;
}

.keyRevealed {
  border: 2px solid var(--col);
  border-radius: 4px;
  
  background-color: unset;
  color: hsl(0deg 0% var(--faintlvl));
  
  position: relative;
  top: -1px;
  box-shadow: 0px 1px var(--Dcol);
  
  line-height: 18px;
  
  display: flex;
  flex: 1 1 auto;
  
  transition: border-color 0.2s;
}

.keyRevealed:hover {
  top: 0px;
  box-shadow: 0px 0px var(--Dcol);
}

.keyRevealed:active {
  border-color: var(--Lcol);
  transition: border-color 0s;
}

.keyRevealed .key {
  background-color: transparent;
  color: hsv(0,0,var(--faint));
  padding: 0px;
  margin: 0px;
  
  position: relative;
  left: 0px;
  top: -3px;
  width: 100%;
  
  border: unset;
  outline: unset;
}

.keyRevealed .label {
  visibility: hidden;
  background-color: var(--labelbg);
  color: var(--labeltext);
  font-size: 14px;
  border-radius: 4px;
  
  position: absolute;
  top: 23px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 3;
  
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  
  width: max-content;
  overflow: visible;
}

.keyRevealed:hover .label {
  visibility: visible;
}

.keyRevealed .label::after {
  content: " ";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--labelbg) transparent;
}

.keyRevealed .icon {
  position: relative;
  top: 1px;
  height: 16px;
  image-rendering: pixelated;
  filter: brightness(var(--faintlvl));
}
